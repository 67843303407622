// @flow

import { StyleSheet, css } from 'aphrodite/no-important';
import React from 'react';
import type { Node } from 'react';

type Props = {|
  children: Node,
|};

const Nobr = (props: Props) => {
  return <span className={css(styles.noWrap)}>{props.children}</span>;
};

export default Nobr;

const styles = StyleSheet.create({
  noWrap: {
    whiteSpace: 'nowrap',
  },
});
