// @flow

import React from 'react';
import { Nobr } from './elements';
import { StyleSheet, css } from 'aphrodite/no-important';

import bg from '../img/bg.png';
import { colors, widths } from '../utils/constants';

const Header = () => {
  return (
    <header className={css(styles.header)}>
      <h1 className={css(styles.h1)}>Emily Maskin</h1>
      <h2 className={css(styles.h2)}>
        <span className={css(styles.bracket)}>[&nbsp;</span>
        engineering leader <Nobr>&amp; consultant</Nobr>
        <span className={css(styles.bracket)}>&nbsp;]</span>
      </h2>
    </header>
  );
};

export default Header;

const styles = StyleSheet.create({
  header: {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    color: colors.bgLight,
    textAlign: 'center',
    padding: '150px 40px 100px',
    fontWeight: 300,
    [`@media ${widths.mobile}`]: {
      padding: '80px 40px',
    },
    [`@media ${widths.mobileSmall}`]: {
      padding: '60px 30px',
    },
  },
  h1: {
    fontSize: 44,
    [`@media ${widths.mobile}`]: {
      fontSize: 32,
    },
  },
  h2: {
    fontSize: 18,
    marginTop: 25,
    letterSpacing: 1.6,
    [`@media ${widths.mobile}`]: {
      fontSize: 16,
      marginTop: 15,
      letterSpacing: 1.2,
    },
  },
  bracket: {
    color: colors.navy,
  },
});
