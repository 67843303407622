// @flow

import React from 'react';
import { Section, TextLink } from './elements';
import { StyleSheet, css } from 'aphrodite/no-important';
import { widths } from '../utils/constants';
import me from '../img/me.jpg';

import type { SectionId } from '../App';

type Props = {|
  onScrollToSection: SectionId => void,
|};

const About = (props: Props) => {
  return (
    <Section title="About Me" id="about">
      <div className={css(styles.headshot)}>
        <img className={css(styles.img)} src={me} alt="Emily Maskin" />
      </div>
      <div className={css(styles.bio)}>
        <p className={css(styles.p)}>
          I'm a software developer with 10+ years of programming experience and
          a strong background in leadership. My passion is crafting beautiful
          code into beautiful products, and individual developers into
          exceptional teams.
        </p>
        <p className={css(styles.p)}>
          My technical expertise is on the front end, with additional experience
          in fullstack development, design, UX, writing, and editing. I’m
          especially into React these days.
        </p>
        <p className={css(styles.p)}>
          I’ve spoken at meetups and events in coordination with groups
          including{' '}
          <TextLink
            to="https://www.gracehopper.com/?utm_term=grace%20hopper%20academy&utm_medium=ppc&utm_campaign=GraceHopper_Brand_Exact&utm_source=adwords&hsa_tgt=kwd-186515629451&hsa_ad=155224063439&hsa_net=adwords&hsa_ver=3&hsa_cam=658335516&hsa_kw=grace%20hopper%20academy&hsa_acc=6473224336&hsa_mt=e&hsa_src=g&hsa_grp=28781697290&gclid=Cj0KCQiA05zhBRCMARIsACKDWjdUV-g5-TBI7J5M0fND-GHGP_Y-V1k7_-CYc1S4QtwJfxBMEx0CPs8aAhitEALw_wcB"
            external
          >
            Grace Hopper Academy
          </TextLink>
          ,{' '}
          <TextLink
            to="https://www.meetup.com/Women-in-Software-Engineering-NYC/"
            external
          >
            Women in Software Engineering
          </TextLink>
          ,{' '}
          <TextLink to="https://www.platohq.com/" external>
            Plato
          </TextLink>
          , and{' '}
          <TextLink to="https://flatironschool.com/" external>
            Flatiron School
          </TextLink>
          . I also teach on{' '}
          <TextLink to="https://www.skillshare.com/r/user/emilymaskin" external>
            Skillshare
          </TextLink>
          .
        </p>
      </div>
    </Section>
  );
};

export default About;

const styles = StyleSheet.create({
  bio: {
    flex: 2.7,
    fontWeight: 300,
    lineHeight: 1.6,
    display: 'inline-block',
    [`@media ${widths.mobile}`]: {
      marginLeft: 0,
    },
  },
  headshot: {
    marginRight: 40,
    flex: 1,
    [`@media ${widths.mobile}`]: {
      maxWidth: 240,
      display: 'block',
      margin: '0 auto 40px',
    },
  },
  img: {
    border: '1px solid rgba(92, 75, 81, 0.1)',
    width: '100%',
  },
  p: {
    marginBottom: 20,
  },
});
