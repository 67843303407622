// @flow

import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import { Section } from './elements';
import { colors, widths } from '../utils/constants';

const Writing = () => {
  return (
    <Section title="Writing" id="writing">
      <div className={css(styles.projectItem)}>
        <a
          className={css(styles.link)}
          href="https://blog.usejournal.com/stop-telling-women-to-act-like-a-man-711748dc9083"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={css(styles.thumbnail)}
            src="https://cdn-images-1.medium.com/max/1600/0*iEb-GWuYKuf26p61.jpg"
            alt="Stop Telling Women to 'Act Like a Man'"
          />
          <div>Stop Telling Women to 'Act Like a Man'</div>
        </a>
      </div>
      <div className={css(styles.projectItem)}>
        <a
          className={css(styles.link)}
          href="https://medium.com/@emilymaskin/i-left-journalism-for-tech-and-i-use-all-the-same-skills-f25149e37331"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={css(styles.thumbnail)}
            src="https://cdn-images-1.medium.com/max/1600/0*B82VG8bOowJfmzv8."
            alt="I left journalism for tech ... and I use all the same skills"
          />
          <div>
            I left journalism for tech ... and I use all the same skills
          </div>
        </a>
      </div>
      <div className={css(styles.projectItem)}>
        <a
          className={css(styles.link)}
          href="https://medium.com/@emilymaskin/closures-for-the-modern-age-9701e2d85fa1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={css(styles.thumbnail)}
            src="https://cdn-images-1.medium.com/max/1600/1*TZq_OYGEPIuf72LBbzr8Tg.jpeg"
            alt="Confused By Closures? So's This Guy"
          />
          <div>Confused By Closures? So's This Guy</div>
        </a>
      </div>
    </Section>
  );
};

export default Writing;

const styles = StyleSheet.create({
  projectItem: {
    flex: 1,
    overflow: 'hidden',
    padding: 15,
    backgroundColor: colors.bgDark,
    border: '1px solid rgba(92, 75, 81, 0.1)',
    margin: '15px auto',
    maxWidth: 300,
    ':hover': {
      borderColor: 'rgba(92, 75, 81, 0.3)',
    },
    ':first-child': {
      [`@media ${widths.tablet}, ${widths.desktop}`]: {
        marginRight: 15,
      },
    },
    ':last-child': {
      [`@media ${widths.tablet}, ${widths.desktop}`]: {
        marginLeft: 15,
      },
    },
  },
  link: {
    display: 'block',
    overflow: 'hidden',
  },
  thumbnail: {
    display: 'block',
    height: 150,
    border: '1px solid rgba(92, 75, 81, 0.1)',
    marginBottom: 15,
    [`@media ${widths.mobile}`]: {
      width: '100%',
      height: 'auto',
    },
  },
});
