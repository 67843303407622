// @flow

import React from 'react';
import type { Node } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import { colors, widths } from '../../utils/constants';

type Props = {|
  title: string,
  children: Node,
  id?: string,
|};

const Section = (props: Props) => {
  return (
    <section className={css(styles.section)} id={props.id}>
      <h3 className={css(styles.h3)}>{props.title}</h3>
      <div className={css(styles.sectionContent)}>{props.children}</div>
    </section>
  );
};

export default Section;

const styles = StyleSheet.create({
  h3: {
    textAlign: 'center',
    color: colors.red,
    fontSize: 24,
    marginBottom: 40,
    ':after': {
      content: '" "',
      width: 60,
      height: 1,
      margin: '12px auto 0 auto',
      backgroundColor: 'rgba(92, 75, 81, 0.2)',
      display: 'block',
    },
  },
  section: {
    padding: 60,
    clear: 'both',
    position: 'relative',
    ':nth-child(even)': {
      backgroundColor: colors.bgDark,
    },
    [`@media ${widths.mobile}`]: {
      padding: 40,
    },
    [`@media ${widths.mobileSmall}`]: {
      padding: 30,
    },
  },
  sectionContent: {
    maxWidth: 800,
    display: 'flex',
    alignItems: 'flex-start',
    margin: '0 auto',
    [`@media ${widths.mobile}`]: {
      flexDirection: 'column',
    },
  },
});
