// @flow

import React from 'react';
import type { Node } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import { Section } from './elements';
import { colors, widths } from '../utils/constants';

type EntryProps = {|
  company?: string,
  location?: string,
  children?: Node,
|};

const Entry = (props: EntryProps) => (
  <div className={css(styles.entry)}>
    <div className={css(styles.job)}>
      <p className={css(styles.company)}>
        {props.link ? (
          <a
            href={props.link}
            className={css(styles.companyLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.company}
          </a>
        ) : (
          props.company
        )}
      </p>
      <p className={css(styles.location)}>{props.location}</p>
    </div>
    {props.children}
  </div>
);

type PositionProps = {|
  title: string,
  dates?: string,
  duties?: Array<string>,
|};

const Position = (props: PositionProps) => {
  return (
    <div>
      <div className={css(styles.position)}>
        <p className={css(styles.title)}>{props.title}</p>
        <p className={css(styles.dates)}>{props.dates}</p>
      </div>
      <ul className={css(styles.duties)}>
        {props.duties ? props.duties.map((d, i) => <li key={i}>{d}</li>) : null}
      </ul>
    </div>
  );
};

const Resume = () => {
  return (
    <Section title="R&eacute;sum&eacute;" id="resume">
      <div>
        <h4 className={css(styles.h4)}>Experience</h4>
        <Entry company="Adobe" location="New York, NY">
          <Position
            title="Senior Computer Scientist"
            dates="Dec 2021-Present"
            duties={[]}
          />
        </Entry>
        <Entry company="Freelance Web Development" location="New York, NY">
          <Position
            title="Independent Contractor"
            dates="Feb 2020-June 2023"
            duties={[]}
          />
        </Entry>
        <Entry
          company="Simple Health"
          location="New York, NY"
          link="https://www.simplehealth.com"
        >
          <Position
            title="VP of Client Engineering"
            dates="Aug 2019-Jan 2020"
            duties={[
              'Co-owned evolution of engineering organization’s processes, standards, and values',
              'Represented engineering interests and contributed to business strategy as member of company leadership team',
            ]}
          />
          <Position
            title="Director of Client Engineering"
            dates="Aug 2018-Aug 2019"
            duties={[
              'Managed web and mobile teams to reliably deliver all projects on time without sacrificing quality, with a focus on success and growth of both the individual and the company',
              'Led implementation and release of birth control offering and new company identity',
              'Fostered team morale and facilitated smooth transition during company reorganization that resulted in intense pressure and high emotions',
              'Recruited, interviewed, and hired developers as team grew, with focus on diversity and inclusion',
            ]}
          />
          <Position
            title="Engineering Manager"
            dates="Oct 2016-Aug 2018"
            duties={[
              'Led creation and launch of business’s first web product, simplecontacts.com',
              'Collaborated to implement and iterate upon first-of-its-kind online eye exam',
              'Mentored junior developers to become high-performing leaders',
            ]}
          />
        </Entry>

        <Entry
          company="Quartet Health"
          location="New York, NY"
          link="https://www.quartethealth.com"
        >
          <Position
            title="Software Engineer"
            dates="Jan 2016-Oct 2016"
            duties={[
              'Led release of new roles and permissions system to better serve multi-user practices',
              'Rebuilt entire public-facing site with admin interface',
              'Executed new health assessment functionality to improve patient matching with providers',
            ]}
          />
        </Entry>

        <Entry company="Keep" location="New York, NY">
          <Position
            title="Front End Lead"
            dates="Sept 2014-Dec 2015"
            duties={[
              'Managed front-end team projects and practices for optimal productivity and consistent delivery',
              'Oversaw conversion of universal shopping cart from native iOS to mobile webview',
              'Implemented platform to integrate unobtrusive, attractive advertising into web product',
              'Recruited and interviewed front-end candidates',
            ]}
          />
          <Position
            title="Front End Engineer"
            dates="Sept 2013-Sept 2014"
            duties={[
              'Developed interactive e-commerce and social features across the stack',
              'Created and maintained visual component library emphasizing beauty and simplicity',
              'Built administrative interface for order processing',
              'Partnered with design and marketing teams to strategically engage target audience',
            ]}
          />
        </Entry>

        <Entry
          company="Sailthru"
          location="New York, NY"
          link="https://www.sailthru.com"
        >
          <Position
            title="Front End Developer"
            dates="May 2011-Aug 2013"
            duties={[
              'Independently redesigned and built new product interface for improved user experience',
              'Collaborated with marketing team to produce professional, attractive communications',
              'Assisted customers in building clean, cross-client compatible templates',
            ]}
          />
        </Entry>

        <Entry
          company="Code and Theory"
          location="New York, NY"
          link="https://www.codeandtheory.com"
        >
          <Position
            title="Junior Developer"
            dates="Sept 2010-May 2011"
            duties={[
              'Developed full-stack solutions for major brands including Vogue and Dr. Pepper',
              'Expanded search functionality for internal content management system',
              'Optimized sites for faster performance',
            ]}
          />
        </Entry>

        <h4 className={css(styles.h4)}>Education</h4>
        <Entry company="Skidmore College">
          <Position title="Bachelor of Arts in French and Spanish (magna cum laude)" />
        </Entry>
        <Entry company="Various Institutions">
          <Position title="Additional coursework in advanced JavaScript, software design and analysis, discrete structures, linear algebra, calculus, and graphic design" />
        </Entry>

        <h4 className={css(styles.h4)}>Skills</h4>
        <Entry company="Languages">
          <Position title="JavaScript/ES6, GraphQL, CSS/Sass/LESS, HTML, Python, SQL, TypeScript" />
        </Entry>
        <Entry company="Frameworks">
          <Position title="React, Redux, MobX, React Native, Node, Express, Gatsby, Shopify, Django" />
        </Entry>
        <Entry company="Tools">
          <Position title="Git, Webpack, Jest, Flow, PhotoShop, Illustrator, InDesign, Contentful, Prismic" />
        </Entry>
        <Entry company="Human Languages">
          <Position title="Fluent French, Proficient Spanish, Basic Italian and German" />
        </Entry>
      </div>
    </Section>
  );
};

export default Resume;

const styles = StyleSheet.create({
  h4: {
    color: colors.red,
    fontWeight: 300,
    textTransform: 'uppercase',
    fontSize: 16,
    margin: '20px 0 10px 0',
  },
  entry: {
    padding: '10px 0',
    position: 'relative',
    lineHeight: 1.5,
    boxSizing: 'border-box',
    width: '100%',
  },
  job: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  company: {
    color: colors.green,
    fontWeight: 400,
    fontSize: 18,
  },
  companyLink: {
    color: 'inherit',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  position: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 5,
    [`@media ${widths.mobile}`]: {
      flexDirection: 'column',
    },
  },
  title: {
    fontWeight: 400,
  },
  column: {
    width: '40%',
    float: 'left',
    ':first-child': {
      marginRight: '20%',
    },
  },
  location: {
    textTransform: 'uppercase',
    color: colors.gray,
    fontWeight: 400,
    lineHeight: '24px',
  },
  duties: {
    listStylePosition: 'inside',
    fontWeight: 300,
  },
});
