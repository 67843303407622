// @flow

import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { colors } from '../../utils/constants';

import type { Node } from 'react';

type Props = {|
  external?: boolean,
  children: Node,
  to?: string,
  onClick?: () => void,
|};

const TextLink = (props: Props) => {
  return (
    <a
      href={props.to}
      target={props.external ? '_blank' : null}
      className={css(styles.link)}
      onClick={props.onClick}
    >
      {props.children}
    </a>
  );
};

export default TextLink;

const styles = StyleSheet.create({
  link: {
    color: colors.green,
    fontWeight: 400,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
});
